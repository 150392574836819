import { getUserToken } from "../misc/authHelper";

import { Select } from 'antd';
const { Option } = Select;


//=======================================================================
//  UTILITY FUNCTIONS
//=======================================================================
  
//---------------------------------------------------------------------
export const convertNiceDateToSql = ( inStr ) => {
//---------------------------------------------------------------------
if(!inStr) inStr = "";
if(inStr === "") return "";
//03-Nov-23 

inStr = inStr.toUpperCase();

inStr = inStr.replace('JAN', '01');
inStr = inStr.replace('FEB', '02');
inStr = inStr.replace('MAR', '03');
inStr = inStr.replace('APR', '04');
inStr = inStr.replace('MAY', '05');
inStr = inStr.replace('JUN', '06');
inStr = inStr.replace('JUL', '07');
inStr = inStr.replace('AUG', '08');
inStr = inStr.replace('SEP', '09');
inStr = inStr.replace('OCT', '10');
inStr = inStr.replace('NOV', '11');
inStr = inStr.replace('DEC', '12');

console.log(inStr);
console.log(inStr.substring(1, 2));
if(inStr.substring(1, 2) === "/") inStr = "0" + inStr;
console.log(inStr);

inStr = inStr.replace(/[-+$,.()/\\s]/g, '');
console.log(inStr);

if(inStr.substring(2, 2) === "/") inStr = "0" + inStr;
console.log(inStr);

inStr = '20' + inStr.substring(4, 6) + '-' + inStr.substring(0, 2) + '-' + inStr.substring(2, 4) + ' ' + inStr.substring(7, 12);

return inStr;
};
//---------------------------------------------------------------------


//---------------------------------------------------------------------
export const convertNiceAmtToSql = ( inStr ) => {
//---------------------------------------------------------------------
  if (!inStr) inStr = "";
  inStr = inStr.replace(/[-+$,()\s]/g, '');

  return inStr;
};
//---------------------------------------------------------------------
  
  
//---------------------------------------------------------------------
export const BWN = ( inStr ) => {
//---------------------------------------------------------------------
  if (!inStr) inStr = "";
  if ( !Number.isNaN(Number(inStr)) ) inStr = inStr.toString();
    return inStr;
  };
//---------------------------------------------------------------------


//---------------------------------------------------------------------
export const BWZ = ( inStr ) => {
  //---------------------------------------------------------------------
    if (!inStr) inStr = "";
    if ( !Number.isNaN(Number(inStr)) ) inStr = inStr.toString();
    if (inStr === "0") inStr = "";
    return inStr;
  };
//---------------------------------------------------------------------
  
  
//---------------------------------------------------------------------
export const showIfExisting = ( recID ) => {
  const showVisible = { visibility:'visible', cursor:'pointer', color:'red', textDecoration:'underline', fontWeight:'bold', marginBottom:'20px' };
  const showHidden = { visibility:'hidden' };

  if( !recID ) recID = ""
  if ( recID === "" ) {
    return( showHidden )
  }
  else{
    return( showVisible )
  }
}
//---------------------------------------------------------------------


//---------------------------------------------------------------------
export const getFileFromS3 = async(s3URL, prettyName) => {
//---------------------------------------------------------------------
  console.log("Running Download:", s3URL, prettyName)
  const parts = s3URL.split('/');
  const lastPart = parts[parts.length - 1];
  console.log(lastPart);
  await fetch(process.env.REACT_APP_BASE_URL + "getPreSignedURL", {
    body: JSON.stringify(
      {
        url: lastPart
      }
    ),
    headers: { 
      "Accept": "application/json", 
      "Content-Type": "application/json",
      "accesstoken": getUserToken()
    },
    method: "POST"
  })

  .then(res => res.text())
  .then(responseText => {
    console.log(responseText)
    fetch(responseText)
    .then(res => {
      if (!res.ok) {
        throw new Error('Network response was not ok ' + res.statusText);
      }
      return res.blob();
    })
    .then(blob => {
      const url = window.URL.createObjectURL(blob);
      const aElement = document.createElement('a');
      aElement.href = url;
      aElement.download = prettyName;
      document.body.appendChild(aElement);
      aElement.click();
      document.body.removeChild(aElement);
      window.URL.revokeObjectURL(url);
    })
  })
  .catch((err) => {
    alert("Failed to fetch file from S3")
    console.log("ERR: ", err)
  })
}
  

//---------------------------------------------------------------------
//  SYSCODE DATA
//---------------------------------------------------------------------
export const renderSysCodes = ( codeType, label ) => {
  const sysCodesData = require("../data/SysCodes.json");
  const sysCodes = sysCodesData[0];
  const inputFields = { color:'blue' }
  const records = sysCodes.filter( (sysCodes) => sysCodes.xsType === codeType)
  return (
    <Select required style={inputFields} showSearch placeholder={label}>
      <Option value=""></Option>)
      {records.map((line, i) => <Option key={i} value={line.xsCode}>{line.xsDescr}</Option>)}
  </Select>
  )
};



//---------------------------------------------------------------------
//  STYLES
//---------------------------------------------------------------------
export const pageHeaderStyle = {
  verticalAlign: 'top',
  fontSize: 28,
  marginLeft: 20,
  marginRight: 20,
  marginTop: 20,
  marginBottom: 10
};
