import React, { useState, useEffect, lazy, Suspense } from 'react'
import { HashRouter, Route, Routes, Link } from 'react-router-dom'
import { Menu } from 'antd'
import { AuditOutlined, BankOutlined, ClusterOutlined, FormOutlined, HomeOutlined, IdcardOutlined, 
  LineChartOutlined, ProjectOutlined,ReconciliationOutlined,
  TableOutlined, PictureOutlined } from '@ant-design/icons'


import Login from './login'
import Home from './Home'

import ClaimHome from './Claim/ClaimHome'

import Profile from './Profile'
import Protected from './Protected'
import Settings from './Settings'


//LazyLoad the things that arent needed on the first load
const Explorer = lazy(() => import('./Documents/Explorer'))
const CompaniesDir = lazy(() => import('./Companies/CompaniesDir'))
const ClientsDir = lazy(() => import('./Clients/ClientsDir'))
const PersonsDir = lazy(() => import('./Personnel/PersonsDir'))
const PersonAssigns = lazy(() => import('./Personnel/PersonAssigns'))

const DocsList = lazy(() => import('./Documents/DocsList'))
const DocImages = lazy(() => import('./Documents/DocImages'))

const ClaimLogs = lazy(() => import('./Claim/ClaimLogs'))
const ClaimReminders = lazy(() => import('./Claim/ClaimReminders'))
const Reminders = lazy(() => import('./Claim/Reminders'))

const CostsList = lazy(() => import('./Costs/CostsList'))
const Referrals = lazy(() => import('./Referrals/Referrals'))

const Estimates = lazy(() => import('./Costs/Estimates'))
const Invoices = lazy(() => import('./Costs/Invoices'))

const StatsList = lazy(() => import('./Refs/StatsList'))

const { SubMenu } = Menu;
const Router = (props) => {
  const [current, setCurrent] = useState('login')

  const [isAdminVisible, setIsAdminVisible] = useState(localStorage.getItem('IsAdminVisible'));

  useEffect(() => {
    console.log("currentPage: ", current)
  },[current])

  // function setRoute() {
  //   const location = window.location.href.split('/')
  //   const pathname = location[location.length-1]
  //   setCurrent(pathname ? pathname : 'login')
  // }
  
  return (
    <HashRouter>
       <div>
      <Menu selectedKeys={[current]} mode="horizontal" onClick={(e) => setCurrent(e.key)}>
        <Menu.Item key='home'>
          <Link to={`/home`}>
            <HomeOutlined />Home
          </Link>
        </Menu.Item>

        <Menu.Item key='reminders'>
          <Link to={`/reminders`}>
            <FormOutlined />Reminders
          </Link>
        </Menu.Item>

        <Menu.Item key='claims'>
          <Link to={`/claimhome`}>
            <FormOutlined />Claim Home
          </Link>
        </Menu.Item>

        <SubMenu key='projectdocs' icon={<ProjectOutlined />} title='Documents and Images'>
          <Menu.Item key='docslist'>
            <Link to={`/docslist`}>
              <TableOutlined />Claim Documents
            </Link>
          </Menu.Item>
          <Menu.Item key='docImages'>
            <Link to={`/docimages`}>
              <PictureOutlined />Claim Images
            </Link>
          </Menu.Item>
        </SubMenu>

        <Menu.Item key='claimlogs'>
          <Link to={`/claimlogs`}>
          <ReconciliationOutlined />Claim Logs
          </Link>
        </Menu.Item>

        <Menu.Item key='claimreminders'>
          <Link to={`/claimreminders`}>
          <ReconciliationOutlined />Claim Reminders
          </Link>
        </Menu.Item>


        <Menu.Item key='referrals'>
          <Link to={`/referrals`}>
            <AuditOutlined />Referrals and Deliveries
          </Link>
        </Menu.Item>

        <Menu.Item key='costslist'>
            <Link to={`/costslist`}>
              <AuditOutlined />Medical Service Costs
            </Link>
        </Menu.Item>

        <SubMenu key='costs' icon={<AuditOutlined />} title='Home Mods Costs'>
          <Menu.Item key='HMEstimates'>
            <Link to={`/estimates`}>
              <AuditOutlined />Home Mods Estimates
            </Link>
          </Menu.Item>
          <Menu.Item key='HMInvoices'>
            <Link to={`/invoices`}>
              <IdcardOutlined />Home Mods Invoices
            </Link>
          </Menu.Item>
        </SubMenu>

        <SubMenu key='directory' icon={<AuditOutlined />} title='Companies and Persons'>
          <Menu.Item key='CompaniesDir'>
            <Link to={`/CompaniesDir`}>
              <ClusterOutlined />Service Providers
            </Link>
          </Menu.Item>
          <Menu.Item key='personsdir'>
            <Link to={`/personsdir`}>
              <IdcardOutlined />People
            </Link>
          </Menu.Item>
          <Menu.Item key='ClientsDir'>
            <Link to={`/ClientsDir`} style={{ visibility: isAdminVisible}}>
              <BankOutlined />Clients
            </Link>
          </Menu.Item>
          {/* <Menu.Item key='partnerslas'>
            <Link to={`/partnerslas`}>
              <ScheduleOutlined />Partner SLAs
            </Link>
          </Menu.Item> */}
        </SubMenu>

        {/* <SubMenu key='config' icon={<SettingOutlined />} title='System Configuration'>
          <Menu.Item key='configusers'>
            <Link to={`/configusers`}>
              <ReconciliationOutlined />System Users
            </Link>
          </Menu.Item>
          <Menu.Item key='configsteps'>
            <Link to={`/configsteps`}>
              <NodeExpandOutlined />Configure Workflow
            </Link>
          </Menu.Item>
          <Menu.Item key='configsor'>
            <Link to={`/configsor`}>
              <TableOutlined />Schedules of Rates
            </Link>
          </Menu.Item>
          <Menu.Item key='configcodes'>
            <Link to={`/configcodes`}>
              <ReconciliationOutlined />System Codes
            </Link>
          </Menu.Item>
          <Menu.Item key='configtexts'>
            <Link to={`/configtexts`}>
              <ReconciliationOutlined />System Texts
            </Link>
          </Menu.Item>
          <Menu.Item key='configstddocs'>
            <Link to={`/configstddocs`}>
              <ReconciliationOutlined />Standard Doc Templates
            </Link>
          </Menu.Item>
        </SubMenu> */}

        <Menu.Item key='tableau'>
          <a href="https://prod-apsoutheast-a.online.tableau.com/#/site/nstruc/projects/138785" target="_tableau">
            <LineChartOutlined />Tableau Reporting
          </a>
        </Menu.Item>

        <Menu.Item key='settings'>
          <Link to={`/profile`}>
            <AuditOutlined />User Profile
          </Link>
        </Menu.Item>

      </Menu>
      
    </div>
      <Suspense fallback={<div>Loading...</div>}>
        <Routes>
          <Route exact path="/home" element={< Home />}/>
          <Route exact path="/protected" element={<Protected />} />

          <Route exact path="/reminders" element={<Reminders />} />

          <Route exact path="/ClaimHome" element={<ClaimHome />} />
          <Route exact path="/step" element={<ClaimHome />} />

          <Route exact path="/claimlogs" element={<ClaimLogs />} />
          <Route exact path="/claimreminders" element={<ClaimReminders />} />

          <Route exact path="/referrals" element={< Referrals />} />

          <Route exact path="/costslist" element={< CostsList />} />

          <Route exact path="/estimates" element={< Estimates />} />
          <Route exact path="/invoices" element={< Invoices />} />

          <Route exact path="/docslist" element={< DocsList />} />
          <Route exact path="/docimages" element={< DocImages />} />
          <Route exact path="/explorer" element={< Explorer />} />
          
          <Route exact path="/CompaniesDir" element={<CompaniesDir />} />

          <Route exact path="/personsdir" element={<PersonsDir />} />
          <Route exact path="/personassigns" element={<PersonAssigns />} />

          <Route exact path="/ClientsDir" element={<ClientsDir />} />

          <Route exact path="/statslist" element={<StatsList />}/>

          <Route exact path="/profile" element={<Profile />}/>
          <Route exact path="/userprefs" element={<Settings />}/>
          <Route exact path="/login" element={<Login />}/>

          <Route exact path="/" element={<Home />}/>

          <Route component={Home}/>
        </Routes>
      </Suspense>
    </HashRouter>
  )
}

export default Router
